<template>
  <div class="w-full flex flex-wrap"> 
    <div id="profile-review-collection" class="flex-1">
      <review-search-form 
        @change_order="change_review_order_type" 
        @search = "search_review"
        :class="{ hidden: !hasSearchBar }"
        />
      <template v-if="items && items.length > 0" v-for="review in items" :key="review.id">
        <profile-review-item :review="review"></profile-review-item>
      </template>
      <template v-else>
        <div class="flex flex-col h-[200%] justify-center items-center icon-container">
          <template v-if="loading">
            <div  class="text-center post-item-loading">
            {{ $t('post_index_collection.loading') }}
            </div>
          </template>
          <template v-else>
            <div  class="w-16 h-16 mb-6 icon no_review"></div>
            <div class='text-16px font-bold text-center'>
              {{ $t('public_profiles.collection_empty.review') }}
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

import { defineComponent, ref } from "vue";
import i18next from "i18next";

export default defineComponent({
  props: {
    scope: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      currPage: 0,
      autoload: true,
      // collection list
      hasSearchBar: false,
      loading: false,
      username: '',
      itemsCols: 4,
      itemsRows: 2,
      itemsCount: 8,
      items_not_found: false,
      items_data: [],
      items: [],
      o : ref(''),
      kw : ref(''),
    };
  },
  mounted() {
    this.setupItemColsCount()
    this.username = this.getUsernameFromURL();

    this.fetchItemData(this.currPage, this.itemsCount*2)
    this.currPage += 1

    window.addEventListener("scroll", () => {
      if (this.autoload == false) {
        return;
      }

      const scrollableHeight =
        document.body.scrollHeight - window.innerHeight - 150;
      const scrolledDistance = window.scrollY;

      if (scrolledDistance >= scrollableHeight) {
        if (this.loading == false) {
          this.fetchItemData();
        }
      }
    });
  },
  methods: {
    getUsernameFromURL() {
      const url = window.location.href;
      const match = url.match(/\/p\/([^\/]+)\/reviews/) ;
      return match ? match[1] : '';
    },
    
    async fetchItemData(currPage, perPage) {
      const url = `/api/v1/profiles/${this.username}/get_reviews`;
      this.loading = true;
      this.items_not_found = false;
      // 資料要往後拉，所以 +1
      let curr_params = {
        page: (currPage || this.currPage) + 1,
        per_page: perPage || this.itemsCount,
        o: this.o,
        kw: this.kw,
      };
      
      try {
        const response = await fetch(
          url + "?" + new URLSearchParams(curr_params)
        );

        if (response.ok) {
          const data = await response.json();
          const itemsData = data["data"];

          if (itemsData.length > 0) {
            this.items.push(...itemsData);
            this.currPage++; // 更新頁碼
          }

          if (curr_params["page"] > data["total_pages"]) {
            this.autoload = false;
          }
          this.loading = false;

          this.initializeSearchBarVisibility()
        }
      } catch (error) {
        console.log(error);
        this.items = [];
        this.autoload = false;
      }
    },

    change_review_order_type(type) {
      if (this.o == type) {
        return
      }

      this.items = []
      this.o = type
      this.currPage = 0
      this.loading = true
      this.fetchItemData(this.currPage, this.itemsCount*2)
    },

    search_review(kw){
      if (this.kw == kw) {
        return
      }

      this.items = []
      this.kw = kw
      this.currPage = 0
      this.loading = true
      this.fetchItemData(this.currPage, this.itemsCount*2)
    },

    initializeSearchBarVisibility(){
      if(!this.hasSearchBar){
        this.hasSearchBar = (this.items && this.items.length > 0)
      }
    },

    setupItemColsCount() {
      if (window.innerWidth >= 1024) {
        this.itemsCols = 4;
      } else if (window.innerWidth >= 780) {
        this.itemsCols = 3;
      } else if (window.innerWidth >= 705) {
        this.itemsCols = 2;
      } else {
        this.itemsCols = 1;
      }

      if (this.itemsCols != 1) {
        this.itemsCount = this.itemsCols * this.itemsRows;
      } else {
        this.itemsCount = 10;
      }
    },
  }
})
</script>

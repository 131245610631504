<template>
  <div class="w-full flex flex-col flex-wrap"> 
    <div class="flex items-center mb-4">
      <a href="../collections" class="text-12px">{{ $t('post_index_collection.collections') }}</a>
       <img src="../../../../images/breadcrumb_arrow.svg" class="inline-block mx-2 w-4 h-4" alt="Breadcrumb Arrow" />
       <span class="text-12px text-grey-5">{{ collection.title }}</span>
    </div>

    <div id="post-item-collection" class="flex-1">
      <template v-if="state.items.length > 0" v-for="post in state.items" :key="post.id">
        <post-item :post="post"></post-item>
      </template>
      <div v-else-if="state.loading" class="text-center post-item-loading">
        {{ $t('post_index_collection.loading') }}
      </div>
    </div>
  </div>
</template>

<script>

import { defineComponent, reactive, onMounted } from "vue";

export default defineComponent({
  props: {
    scope: {
      type: String,
      required: true
    },
    collection: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const state = reactive({
      currPage: 0,
      hasMore: true,
      username: '',
      is_authenticated: false,
      loading: false,
      itemsCols: 4,
      itemsRows: 2,
      itemsCount: 8,
      items: [],
    });

    const getUsernameFromURL = () => {
      const url = window.location.href;
      const match = url.match(/\/p\/([^\/]+)\/collections/);
      return match ? match[1] : '';
    };

    const fetchItemData = async (page = state.currPage + 1, perPage = state.itemsCount) => {
      if (state.loading || !state.hasMore) return;

      state.loading = true;
      const url = `/api/v1/profiles/${state.username}/get_posts_by_ids`;
      const params = new URLSearchParams();
      props.collection.post_ids.forEach((id) => params.append('ids[]', id));
      params.append('page', page);
      params.append('per_page', perPage);

      try {
        const response = await fetch(`${url}?${params.toString()}`);
        if (!response.ok) throw new Error(`Error: ${response.status}`);
        const data = await response.json();

        if (page === 1) {
          state.items = [...data.data];
        } else {
          state.items = [...state.items, ...data.data];
        }

        state.currPage = data.current_page;
        state.hasMore = state.currPage < data.total_pages;

      } catch (error) {
        console.error("Error fetching items:", error);
        state.hasMore = false;
      } finally {
        state.loading = false;
      }
    };

    const handleScrollEvent = () => {
      const scrollableHeight = document.body.scrollHeight - window.innerHeight - 150;
      const scrolledDistance = Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
      );

      if (scrolledDistance >= scrollableHeight && state.hasMore && !state.loading) {
        fetchItemData();
      }
    };

    const setupItemColsCount = () => {
      if (window.innerWidth >= 1024) {
        state.itemsCols = 4;
      } else if (window.innerWidth >= 780) {
        state.itemsCols = 3;
      } else if (window.innerWidth >= 705) {
        state.itemsCols = 2;
      } else {
        state.itemsCols = 1;
      }
      state.itemsCount = state.itemsCols === 1 ? 10 : state.itemsCols * state.itemsRows;
    };

    onMounted(() => {
      state.username = getUsernameFromURL();
      setupItemColsCount();
      fetchItemData();
      window.addEventListener('scroll', handleScrollEvent);
    });

    return {
      state,
      fetchItemData,
      handleScrollEvent
    };
  }
})
</script>
